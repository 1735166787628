
import { FiGithub } from "react-icons/fi";

export const ExpCard = ({
  type,
  name,
  description,
  tool1,
  tool2,
  tool3,
  tool4,
  icon1,
  icon2,
  icon3,
  icon4,
  path,
  img,
}) => {
  return (
    <div className="md:w-[50rem] md:h-[15rem] w-[20.5rem] h-[35rem] p-4 rounded-2xl bg-white  shadow-lg  flex flex-col  md:flex-row-reverse">
      <div className=" h-full w-full  shadow-md rounded-2xl basis-full relative">
        <div className="h-full w-full relative border-2 border-white rounded-2xl overflow-hidden">
          <img
            src={img}
            alt="Travel"
            className="px-1 md:mt-3 mt-10 hover:-translate-y-4 duration-200 object-scale-down"
          />
        </div>
      </div>
      <div className=" h-full w-full mr-2 rounded-2xl ">
        <p className="m-2 font-bold pl-1 text-lg text-baby">{type}</p>
        <h1 className="m-2 text-2xl font-bold dark:text-black">
          {name}
          <span className="max-h-[7rem] block mr-1 font-normal text-lg mt-4 text-left overflow-hidden overflow-y-scroll">
            {description}
          </span>
        </h1>

        </div>
      
    </div>
  );
};
