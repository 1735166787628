import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Twirl as Hamburger } from 'hamburger-react'
import { Carditem } from "./Carditem";
import { Card } from "./Card"
import { Card2 } from "./Card-copy"
import { Link } from "react-scroll"
import { ExpCard } from "./ExpCard";

import { FaReact } from "react-icons/fa";
import {
  SiTypescript,
  SiMicrosoftazure,
  SiKeras,
  SiAnaconda,
  SiTensorflow,
  SiPython,
  SiDjango,
  SiTwilio,
  SiAmazonaws,
  SiDocker,
  SiOracle,
  SiFlask,
} from "react-icons/si";
import { AiOutlineClockCircle } from "react-icons/ai";
import { VscChecklist } from "react-icons/vsc";
import { FaPlay } from "react-icons/fa";
import { MoonIcon } from "@heroicons/react/solid";
import { SunIcon } from "@heroicons/react/solid";


function App() {

  document.title = "Lawrence Tang"

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const targets = document.querySelectorAll(".show-on-scroll");

  const callback = function (entries) {
    const seen = new Set();
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("motion-safe:animate-fadeIn");
      } else {
          entry.target.classList.remove("motion-safe:animate-fadeIn");
      }
    });
  };
  const observer = new IntersectionObserver(callback);

  targets.forEach(function (target) {
    target.classList.add("opacity-0");
    observer.observe(target);
  });

  let hamburger = document.querySelector("#hamburger");
  let menu = document.querySelector("#menu");

  hamburger?.addEventListener("click", function () {
    console.log("clicked");
    if (menu.classList.contains("hidden")) {
      menu.classList.remove("hidden");
    } else {
      menu.classList.add("hidden");
    }
  });
  return (
    <>
      <header class="py-6 ">
        <div class="container flex justify-between items-center mx-auto px-8 md:px-14 lg:px-24 w-full">
          <div class="text-lg font-bold">Lawrence.汤</div>
          <div class="hidden md:flex space-x-12 items-center">
            <a href="#about me" class="text-theme">
              About Me
            </a>
            <a href="#project">Projects</a>
            <a href="#experiences">Experiences</a>
            <a href="Resume.pdf" target="_blank">
              <button class="px-6 py-2 border-2 border-theme hover:text-baby hover:border-baby font-bold rounded-md">
                Resume
              </button>
            </a>
          </div>
          <div class="md:hidden">
            <Menu as="div" className="relative inline-block text-left">
              <Menu>
                {({ open }) => (
                  <>
                    <Menu.Button className="inline-flex justify-center rounded-md  px-4  text-sm font-medium text-black ">
                      <Hamburger size={25} toggled={open} />
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-5 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1 ">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#about me"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                About Me
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#project"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Projects
                              </a>
                            )}
                          </Menu.Item>{" "}
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#experiences"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Experiences
                              </a>
                            )}
                          </Menu.Item>{" "}
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm w-full text-theme font-bold rounded-md text-left"
                                )}
                              >
                                Resume
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </Menu>
          </div>
        </div>
      </header>

      <div class="container mt-16 flex justify-between items-center mx-auto px-8 md:px-14 lg:px-24 w-full">
        <div class="flex flex-wrap md:flex-nowrap">
          <div class="flex flex-wrap lg:ml-20 justify-center md:justify-start max-w-max mt-0 md:my-36">
            <h1 class="font-bold text-5xl md:text-6xl lg:text-7xl text-center md:text-left">
              Hello!
              <br />
              <p className="mt-3">
                I am {""}
                <span className="inline-block p-1 border-2 border-theme font-bold rounded-md">
                  Lawrence Tang.
                </span>
              </p>
              <br />{" "}
              <span className="text-4xl">Combined CPSC & STAT @ UBC</span>
            </h1>
            <div class="w-full flex gap-2 justify-center md:justify-start">
              <a
                target="_blank"
                href="Resume.pdf"
                class="hover:cursor-pointer  px-3 py-2 rounded-md bg-theme text-white font-bold mt-12 flex items-center space-x-3"
              >
                <span>Resume</span>
              </a>
              <a
                href="https://github.com/Lawrence-tng"
                target="_blank"
                class="hover:cursor-pointer px-3 py-2 rounded-md bg-theme text-white font-bold mt-12 flex items-center space-x-3"
              >
                <span>Github</span>
              </a>
              <a
                href="https://medium.com/@lxren460"
                target="_blank"
                class="hover:cursor-pointer px-3 py-2 rounded-md bg-theme text-white font-bold mt-12 flex items-center space-x-3"
              >
                <span>Medium</span>
              </a>
              <a
                href="https://www.linkedin.com/in/ltang01/"
                target="_blank"
                class="hover:cursor-pointer  px-3 py-2 rounded-md bg-theme text-white font-bold mt-12 flex items-center space-x-3"
              >
                <span>LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="show-on-scroll container mt-40 flex justify-between items-center mx-auto px-8 md:px-14 lg:px-24 w-full">
        <section className="w-full">
          <h2
            id="about me"
            class="secondary-title text-2xl font-poppins font-bold"
          >
            About Me
          </h2>
          <div className="flex">
            <p className="section-paragraph mt-10 text-lg mr-4">
              Hi! My name is <span className="font-bold">Lawrence Tang</span>{" "}
              and I am a 4th year at UBC majoring in Computer Science and
              Statistics.
              <br></br>
              <p className="mt-5">
                I wanted to pursue Computer Science because I love turning my little ideas into reality through code.
                I believe that I have found my passion here and I hope that one day I can be part of a project that could impact the world!
              </p>
              <p className="mt-5">
                {" "}
                I still have a long way to go, but I will improve one leetcode problem at a time :) 
              </p>
            </p>
            <img
              src="Profile2.jpg"
              alt="profile"
              className="hover:-translate-y-2 duration-200 h-[20rem] w-[15rem] object-cover rounded-lg  shadow-lg md:ml-20"
            />
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6"></div>
        </section>
      </div>
      <div className="show-on-scroll container mt-40 flex flex-col justify-between items-left mx-auto px-8 md:px-14 lg:px-24 w-full">
        <h2
          id="experiences"
          class=" secondary-title text-2xl font-poppins font-bold"
        >
          Experiences
        </h2>
        <div className=" rounded-lg mx-8 px-8 py-8">
          <ul className="flex flex-col gap-6 items-center">
            <li>
              <ExpCard
                img="AWSLogo.png"
                type="May 2023 - July 2023"
                name="Software Development Engineer Intern"
                description="I worked with the payments audit team to create an internal auditing platform for service teams within AWS payments"
              />
            </li>
            <li>
              <ExpCard
                img="UBClogo.JPG"
                type="September 2022 - April 2022"
                name="Teaching Assistant"
                description="I was a TA for STAT 200 (Elementary Statistics for Applications) and CPSC 304 (Introduction to Relational Databases) at UBC."
              />
            </li>
            <li>
              <ExpCard
                img="DiligentLogo.JPG"
                type="January 2022 - August 2022"
                name="Software Development Intern"
                description="As a member of the Machine Learning team, I help integrate AI on Diligent's GRC platform to enhance user experience.  "
              />
            </li>
            <li>
              <ExpCard
                img="wun2freeLogo.JPG"
                type="May 2019 - Oct 2019"
                name="Games Attendant"
                description="As a games attendant at the Richmond Night Market, I create a fun and engaging environment by interacting with customers. Although it took me a while to be comfortable with constantly chatting and maintaining a high level of energy, I was awarded the Team Player Award and Best Customer Experience Award at the end of the season."
              />
            </li>
          </ul>
        </div>
      </div>

      <div
        id="project"
        className="show-on-scroll container mt-40 flex flex-col justify-between items-left mx-auto px-8 md:px-14 lg:px-24 w-full"
      >
        <h2 id="work" class=" secondary-title text-2xl font-poppins font-bold">
          Projects
        </h2>
        <div className=" rounded-lg mx-8 px-8 py-8">
          <ul className="flex flex-col gap-6 items-center">
            <li>
              <Card
                img="Let2.jpeg"
                type="Personal Project"
                name="LetMeInUBC 2.0"
                description="Reinvented previous LetMeInUBC (course availability notifier) on serverless infrastructure to dramatically reduce cost - so that we can keep our service free!"
                icon1={<SiAmazonaws size={20} color="#61DBFB" />}
                icon2={<SiDocker size={20} color="#61DBFB" />}
                icon3={<SiTypescript size={20} color="#61DBFB" />}
                icon4={<FaReact size={20} color="#61DBFB" />}
                tool1="AWS CDK"
                tool2="Docker"
                tool3="TypeScript"
                tool4="React"
                path="https://letmeinubc.com/"
              />
            </li>
            <li>
              <Card2
                img="MNIST_Digit.JPG"
                name="MNIST Digit Classifier"
                description="Convolutional neural model trained on MNIST digit dataset to classify user drawn digits"
                type="Personal Project"
                icon1={<SiPython size={20} color="#61DBFB" />}
                icon2={<SiAnaconda size={20} color="#61DBFB" />}
                icon3={<SiKeras size={20} color="#61DBFB" />}
                icon4={<SiTensorflow size={20} color="#61DBFB" />}
                tool1="Python"
                tool2="Anaconda"
                tool3="Keras"
                tool4="Tensorflow"
                path="https://github.com/Lawrence-tng/User-MNIST-Digit-Classifier"
              />
            </li>
            <li>
              <Card
                icon1={<SiMicrosoftazure size={20} color="#61DBFB" />}
                tool1="Azure"
                icon2={<SiDjango size={20} color="#61DBFB" />}
                icon3={<SiTwilio size={20} color="#61DBFB" />}
                tool2="Django"
                tool3="Twilio"
                img="LETMEIN.JPG"
                type="Personal Project"
                name="LetMeInUBC"
                description="LetMeInUBC is an web-app my friends and I built to help our peers register for their courses. Upon entering the class they want, an email/SMS notification will be sent when a spot is available"
                path="https://github.com/kel-z/LetMeInUBC"
              />
            </li>
            <li>
              <Card2
                img="CPSC304.JPG"
                icon1={<SiFlask size={20} color="#61DBFB" />}
                icon2={<SiOracle size={20} color="#61DBFB" />}
                icon3={<SiTypescript size={20} color="#61DBFB" />}
                icon4={<SiPython size={20} color="#61DBFB" />}
                tool1="Flask"
                tool2="Oracle"
                tool3="Typescript"
                tool4="Python"
                name="Carnival Operations DBMS"
                type="Academic Project"
                description="This full-stack application allows users to interact with their operations database and perform analysis ranging from general to complex queries"
              />
            </li>

            <li>
              <Card
                name="A-Star Pathfinding Visualizer"
                description="Visualizes A-star pathfinding algorithm on user drawn maps"
                icon1={<SiPython size={20} color="#61DBFB" />}
                tool1="Python"
                img="APathfinding.JPG"
                type="Personal Project"
                path="https://github.com/Lawrence-tng/pathfindingAStar"
              />
            </li>
          </ul>
        </div>
      </div>
      <div className="container mt-40 mb-40 flex flex-col justify-between items-left mx-auto px-8 md:px-14 lg:px-24 w-full">
        <section className="w-full">
          <h2 id="work" class="secondary-title text-2xl font-poppins font-bold">
            Questions? Connect with me!
          </h2>
          <p className="section-paragraph mt-2 text-lg">
            If you have any questions for me or want to chat in general, feel
            free to contact me through email or LinkedIn.
          </p>
          <div class="mt-10 w-full flex gap-2 justify-center md:justify-start">
            <img
              src="botp.jpg"
              className="shadow-lg h-[8rem] w-[8rem] mr-5 rounded-[50%] object-cover"
            ></img>
            <div className="flex-col">
              <span className="text-xl block font-poppins font-bold">
                Lawrence Tang
              </span>
              <span>Lxren460@gmail.com</span>
              <div className="flex gap-2 mt-5">
                <a
                  target="_blank"
                  href="Resume.pdf"
                  class="hover:cursor-pointer  px-3 py-2 rounded-md bg-theme text-white font-bold mt-2 flex items-center space-x-3"
                >
                  <span>Resume</span>
                </a>
                <a
                  href="https://github.com/Lawrence-tng"
                  target="_blank"
                  class="hover:cursor-pointer px-3 py-2 rounded-md bg-theme text-white font-bold mt-2 flex items-center space-x-3"
                >
                  <span>Github</span>
                </a>
                <a
                  href="https://medium.com/@lxren460"
                  target="_blank"
                  class="hover:cursor-pointer px-3 py-2 rounded-md bg-theme text-white font-bold mt-2 flex items-center space-x-3"
                >
                  <span>Medium</span>
                </a>
                <a
                  href="https://www.linkedin.com/in/ltang01/"
                  target="_blank"
                  class="hover:cursor-pointer  px-3 py-2 rounded-md bg-theme text-white font-bold mt-2 flex items-center space-x-3"
                >
                  <span>LinkedIn</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default App;
