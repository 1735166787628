import { FaReact } from "react-icons/fa";
import { SiTypescript } from "react-icons/si";
import { AiOutlineClockCircle } from "react-icons/ai";
import { VscChecklist } from "react-icons/vsc";
import { FaPlay } from "react-icons/fa";
import { MoonIcon } from "@heroicons/react/solid";
import { SunIcon } from "@heroicons/react/solid";
import { FiGithub } from "react-icons/fi";

export const Card2 = ({type, name, description, tool1, tool2, tool3, tool4, icon1, icon2, icon3, icon4, path, img }) => {
  return (
    <div className="md:w-[50rem] md:h-[22rem] w-[20.5rem] h-[40rem] p-4 rounded-2xl bg-white  shadow-lg  flex flex-col-reverse  md:flex-row-reverse">
      <div className=" h-full w-full ml-2 rounded-2xl ">
        <p className="m-2 font-bold pl-1 text-lg text-baby">{type}</p>
        <h1 className="m-2 text-2xl font-bold dark:text-black">
          {name}
          <span className="block font-normal text-lg text-black mt-4 text-left">
            {description}
          </span>
        </h1>

        <div className=" pt-16 pr-2 pl-2 flex flex-row justify-around flex-wrap">
          <div className="flex flex-row items-center m-2">
            {icon1}
            <h1 className="pl-1 dark:text-black">{tool1}</h1>
          </div>
          <div className="flex flex-row items-center m-2">
            {icon2}
            <h1 className="pl-1 dark:text-black">{tool2}</h1>
          </div>
          <div className="flex flex-row items-center m-2">
            {icon3}
            <h1 className="pl-1 dark:text-black">{tool3}</h1>
          </div>
          <div className="flex flex-row items-center m-2">
            {icon4}
            <h1 className="pl-1 dark:text-black">{tool4}</h1>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <button className="md:m-2 m-auto mt-2 bg-[#5865F2] shadow-md shadow-[#5865f28a]  pt-2 pb-2 pl-6 pr-4 rounded-xl flex flex-row justify-center items-center hover:bg-[#424bb6] ease-linear duration-300">
            <FiGithub className="" size={20} color="#fff" />
            <a
              href={path}
              target="_blank"
              className="text-white text-md font-semibold pl-2"
            >
              Github
            </a>
          </button>
        </div>
      </div>
      <div className=" h-full w-full  shadow-md rounded-2xl basis-2/3 relative">
        <div className="h-full w-full relative border-2 border-white rounded-2xl overflow-hidden">
          <img src={img} alt="Travel Image" className="cards_item_img" />
        </div>
      </div>
    </div>
  );
}
